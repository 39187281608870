<template>
  <div>
    <headerInfo :listKey="listKey"></headerInfo>
    <liff-list :listKey="listKey"></liff-list>
    <add-btn is-fixed :on-click="handleAddProduct"></add-btn>
  </div>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./productListMobileConfig"

export default {
  mixins: [listMixin],
  components: {
    headerInfo: () => import('@/modules/product/components/productListHeaderInfo.vue'),
    'add-btn': () => import('@/components/buttons/addBtn.vue'),
  },
  data: () => ({
    listKey: 'liff-product-list',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      if(params.filter && params.filter.status === 'null') delete params.filter.status
      return await this.$api.collection.productApi.index(this.providerId, params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.productApi.patch(this.providerId, target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(this.providerId, target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.productApi.batch(this.providerId, targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.productApi.batchDelete(this.providerId, targets)
    },
    getListConfig() {
      return listConfig
    },
    handleAddProduct() {
      this.$router.push({
        name: "product-create"
      })
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
